import React, { useContext } from "react"
import classNames from "classnames"

import UploadGuidelinesModal from "../UploadGuidelinesModal"
import UploadDropzoneBody from "../UploadDropzone/UploadDropzoneBody"
import UploadDropzoneLabel from "../UploadDropzone/UploadDropzoneLabel"
import DocumentsCard from "../../Cart/DocumentsCard"

import styles from "../utils/upload.module.scss"
import { AppContext } from "../../../context/AppContext"

const DropzoneBody = ({
  icon,
  label,
  filesUploaded,
  handleFileChooser,
  notifications,
}) => {
  const { state, dispatch } = useContext(AppContext)
  const { documents } = state

  const handleShowGuidelines = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Upload Guidelines",
        isCard: true,
        hideCloseButton: true,
        isActive: true,
        headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-4 has-text-centered`,
        content: <UploadGuidelinesModal handleCloseModal={handleFileChooser} />,
      },
    })
  }

  return (
    <div className={classNames(styles["dropzone__container"])}>
      {filesUploaded?.filter((doc) => doc.type === "RX")?.length > 0 ? (
        <DocumentsCard
          title={`Prescriptions Uploaded (${documents.length})`}
          hideButton
          filesUploaded={filesUploaded}
        />
      ) : (
        <UploadDropzoneBody
          icon={icon}
          onClick={
            filesUploaded.length ? handleFileChooser : handleShowGuidelines
          }
        />
      )}
      {notifications.length > 0 && notifications.map((item) => item)}

      <UploadDropzoneLabel
        label={label}
        onClick={
          filesUploaded.length ? handleFileChooser : handleShowGuidelines
        }
      />
    </div>
  )
}

export default DropzoneBody
