import React, { Fragment } from "react"
import uploadGuidelines from "./utils/uploadGuidelines.json"

const UploadGuidelinesModal = ({ props, handleCloseModal }) => {
  return (
    <Fragment>
      <div className="content is-flex is-justify-content-left">
        <ol className="ml-1">
          {(props?.guidelines || uploadGuidelines).map((item, index) => (
            <li className="pb-0 is-size-6 has-text-left" key={index}>
              {item}
            </li>
          ))}
          <button className="button is-primary mt-2" onClick={handleCloseModal}>
            I understand
          </button>
        </ol>
      </div>
    </Fragment>
  )
}

export default UploadGuidelinesModal
