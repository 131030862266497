import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"

import Upload from "../../components/Upload/Upload"
import Layout from "../../components/Layout/Layout"
import PrescriptionRequired from "../../components/Upload/PrescriptionRequired"

import { AppContext } from "../../context/AppContext"
import { getContextFromSession } from "../../context/services/context"

export default ({ location }) => {
  const { state } = useContext(AppContext)

  let medicinesThatRequireRx = state?.epharmacy?.medicines?.filter(
    (medicine) => {
      return medicine?.rxRequired
    }
  )

  useEffect(() => {
    let currentContext = getContextFromSession()
    let isPartnerRequest = state?.epharmacy?.hasPartnerRequest
    let isWellnessPatient = state?.epharmacy?.isMedGrocerWellnessPatient
    let currentCoupon = state?.epharmacy?.coupon?.code

    const hasMedicines = currentContext?.epharmacy?.medicines?.length > 0

    if (
      (!hasMedicines && !isPartnerRequest && !isWellnessPatient) ||
      (currentCoupon !== "MGMHMEDS" && isWellnessPatient)
    ) {
      navigate("/epharmacy")
    }
  }, [state?.epharmacy?.coupon])

  return (
    <Layout
      title="Upload Documents"
      process="epharmacy"
      subtitle="Upload your prescription and our pharmacist will confirm the total price through email. Orders are not final until email confirmation"
      currentStep={2}
      totalSteps={4}
      seoTitle="Upload Documents"
      display={{ footer: false, helpCenterBanner: false, radialStepper: true }}
      path={location?.pathname}
    >
      {medicinesThatRequireRx?.length > 0 && (
        <PrescriptionRequired medicines={medicinesThatRequireRx} />
      )}

      <Upload
        flow="epharmacy"
        location={location}
        backRoute="/epharmacy/order"
        nextRoute="/epharmacy/est"
      />
    </Layout>
  )
}
