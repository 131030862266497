import React, { useState, useEffect, useContext, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import { isEqual } from "lodash"

import DropzoneBody from "./Dropzone/DropzoneBody"
import UploadErrorNotification from "./Dropzone/UploadErrorNotification"

import { AppContext } from "../../context/AppContext"

import styles from "./utils/upload.module.scss"
import { isIeOrEdge } from "../../services/browserCompatibility"
import { uploadDocument } from "./services/uploadDocuments"
import {
  getContextFromSession,
  saveContextToSession,
} from "../../context/services/context"

const Dropzone = ({
  setFilesUploaded,
  filesUploaded,
  maxByteSize,
  maxFileCount,
  docType,
  children,
}) => {
  const { dispatch, state } = useContext(AppContext)
  const fileInputRef = useRef(null)
  const [notifications, setNotifications] = useState([])
  const [successfullyUploaded, setSuccessfullyUploaded] = useState(0)
  const [uploadingQueue, setUploadingQueue] = useState(null)

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  const data = useStaticQuery(graphql`
    {
      fileUploadSparkle: file(relativePath: { eq: "dropzone__upload.png" }) {
        childImageSharp {
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      prescription: file(
        relativePath: { eq: "icons/upload__prescription.png" }
      ) {
        childImageSharp {
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const fileUploadSparkle = data.fileUploadSparkle.childImageSharp.fixed
  const fileUploadPrescription = data?.prescription?.childImageSharp?.fixed

  const closeNotifications = () => {
    setNotifications([])
  }

  const handleAlreadyUploaded = (setNotifications, setSuccessfullyUploaded) => (
    file
  ) => {
    setNotifications((notificationsList) => {
      let previousNotifications = [...notificationsList]
      previousNotifications.push(
        <UploadErrorNotification
          fileName={file.oldname}
          message="has already been uploaded."
        />
      )
      return previousNotifications
    })
    setSuccessfullyUploaded((previousSuccessfullyUploaded) => {
      return previousSuccessfullyUploaded - 1
    })
  }

  const handleFileRead = (event) => {
    closeNotifications()
    const tempFilesUploaded = [...event.target.files]
    const initialNotifications = []
    let tempSuccessfullyUploaded = 0
    const hasExceededMaxFiles =
      docType === "RX"
        ? tempFilesUploaded.length + filesUploaded.length > maxFileCount
        : tempFilesUploaded.length > maxFileCount
    if (hasExceededMaxFiles)
      initialNotifications.push(
        <UploadErrorNotification
          message={`Please upload only a maximum of ${maxFileCount} file${
            maxFileCount > 1 ? "s" : ""
          }.`}
        />
      )
    else
      for (let i = 0; i < tempFilesUploaded.length; i++) {
        if (tempFilesUploaded[i].size < maxByteSize) {
          uploadDocument(
            tempFilesUploaded[i],
            filesUploaded,
            docType,
            setFilesUploaded,
            // (prevFilesUploaded, newFile) => {
            //   switch (docType) {
            //     case "RX":
            //       dispatch({
            //         type: "SAVE_DOCUMENTS",
            //         payload: [...prevFilesUploaded],
            //       })
            //       break
            //     default:
            //       dispatch({
            //         type: `SAVE_${docType}_ID`,
            //         payload: newFile,
            //       })
            //       break
            //   }
            // },
            null,
            handleAlreadyUploaded(setNotifications, setSuccessfullyUploaded),
            dispatch,
            i
          )
          tempSuccessfullyUploaded += 1
        } else
          initialNotifications.push(
            <UploadErrorNotification
              fileName={tempFilesUploaded[i].name}
              message="is greater than 4MB. Please upload a file or photo less than 4MB."
            />
          )
      }
    setNotifications(initialNotifications)
    if (tempSuccessfullyUploaded > 0)
      setSuccessfullyUploaded(tempSuccessfullyUploaded)
  }

  useEffect(() => {
    let contextData = getContextFromSession() || {}
    if (
      !isEqual(state?.documents, contextData?.documents) &&
      state?.documents?.length > 0
    )
      saveContextToSession(state)
    if (!isEqual(state.documents, filesUploaded))
      setFilesUploaded(state.documents)
  }, [state])

  useEffect(() => {
    if (uploadingQueue) clearTimeout(uploadingQueue)
    setUploadingQueue(
      setTimeout(() => {
        if (successfullyUploaded > 0) {
          // dispatch({
          //   type: "SHOW_TOAST",
          //   payload: {
          //     message: `Successfully uploaded ${successfullyUploaded} file${
          //       successfullyUploaded > 1 ? "s" : ""
          //     }`,
          //     color: "success",
          //   },
          // })
          setSuccessfullyUploaded(0)
        }
      }, 500)
    )
  }, [successfullyUploaded])

  const handleCloseModal = () => {
    dispatch({ type: "HIDE_MODAL" })
    if (isIeOrEdge()) setTimeout(handleFileChooser, 0)
    else handleFileChooser()
  }

  return (
    <div className={classNames("container mt-1", styles["dropzone"])}>
      <input
        type="file"
        className={styles["dropzone__input"]}
        ref={fileInputRef}
        onChange={handleFileRead}
        multiple
        accept="image/*,.pdf"
        value=""
      />
      <DropzoneBody
        icon={fileUploadPrescription}
        label="Upload Prescription"
        filesUploaded={filesUploaded}
        fileUploadSparkle={fileUploadSparkle}
        handleFileChooser={handleCloseModal}
        children={children}
        notifications={notifications}
      />
    </div>
  )
}

export default Dropzone
